<template>
  <nav class="nav">
    <span class="nav__logo">
      <a href="/" @click.prevent="goHome"> CONTINUU| </a>
    </span>

    <transition-group
      tag="router-link"
      class="nav__links"
      name="nav"
      mode="out-in"
      appear
      v-bind:class="{ 'router-container-link-active': activeLink }"
    >
      <router-link
        v-for="(link, index) in navLinks"
        :key="index"
        :data-index="index"
        class="nav__link nav-item"
        :to="link.url"
        @click="toTop($event, link)"
      >
        {{ link.name }}
      </router-link>

      <!-- only render on about -->
      <div
        v-if="
          $route.path == '/continuum-about' ||
          $route.path == '/continuum-about-english'
        "
        class="nav__languageContainer"
      >
        <router-link class="nav__link" to="/continuum-about"> DE </router-link>
        <router-link class="nav__link" to="/continuum-about-english">
          EN
        </router-link>
      </div>
    </transition-group>
    <span class="nav__logo">|M</span>
  </nav>

  <!-- mobile EN/DE only render on about -->
  <div
    v-if="$route.path == '/' || $route.path == '/'"
    class="nav__languageContainer--mobile"
  >
    <router-link class="nav__link" to="/continuum-about"> DE </router-link>
    <router-link class="nav__link" to="/continuum-about-english">
      EN
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeLink: false,
      navLinks: [
        {
          name: "About",
          url: "/continuum-about",
        },
        {
          name: "Projects",
          url: "/projects",
        },
        {
          name: "Media",
          url: "/continuum-media",
        },
        {
          name: "Concerts",
          url: "/concerts",
        },
        {
          name: "Contact",
          url: "/continuum-kontakt",
        },
      ],
    };
  },

  methods: {
    toTop($event, link) {
      //console.dir($event.target.innerHTML);
      let navID = $event.target.dataset.index;
      this.navLinks.splice(navID, 1);
      this.navLinks.unshift(link);

      /* 
      - check if menu item is either concerts or projects (= first of array)
      - if so, make activeLink true -> on nav_links container a new CSS class is conditioanlly rendered: 'router-container-link-active'
      */

      if (
        this.navLinks[0].name == "Concerts" ||
        this.navLinks[0].name == "Projects" ||
        this.navLinks[0].name == "About"
      ) {
        this.activeLink = true;
      } else {
        this.activeLink = false;
      }
    },
    goHome() {
      // don't judge me :)
      let html = document.querySelector("html");
      html.style.transition = "all 1s ease-in-out";
      html.style.opacity = 0;
      html.style.transform = "translateY(-100px)";
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    },
  },
};
</script>


<style lang="scss" scoped>
.nav {
  display: flex;
  justify-content: flex-start;
  line-height: 1;
  letter-spacing: -0.3vh;
  margin-right: 40px;
  font-size: 5vh;

  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
    font-size: 3.3vh;
  }
  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
    font-size: 4vh;
  }

  @include breakExact(1024px) {
    margin-bottom: 60px;
  }

  @include breakExact(700px) {
    font-size: 23px;
    margin-bottom: 50px;
    margin-right: 0;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
    position: relative;

    @include breakExact(700px) {
      margin-right: 10px;
    }
  }

  &__link {
    text-decoration: none;
    text-transform: uppercase;
    color: $gray-700;
    text-align: left;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $black;
    }

    /* clear hover on mobile */
    @include breakExact(1024px) {
      &:hover {
        color: $gray-700;
      }
    }
  }

  &__languageContainer {
    width: 100%;
    margin-top: 40px;
    display: flex;

    a {
      text-decoration: none;
      text-transform: uppercase;
      color: $gray-700;
      cursor: pointer;
      letter-spacing: -0.3vh;
      transition: all 0.3s ease-in-out;

      &:first-child {
        margin-right: 40px;
      }

      &:hover {
        color: $black;
      }

      /* clear hover on mobile */
      @include breakExact(1024px) {
        &:hover {
          color: $gray-700;
        }
      }
    }

    &--mobile {
      display: none;

      .router-link-active {
        color: $black;
      }

      a:first-child {
        margin-right: 10px;
      }

      @include breakExact(1024px) {
        display: block;

        .nav__link {
          font-size: 2.3vh;
        }
      }
    }

    @include breakExact(1024px) {
      display: none;
    }
  }

  &__logo {
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: -0.3vh;

    &:first-child {
      a:hover {
        color: $gray-700;
      }
    }
  }

  /* active black menu item */
  .router-link-active {
    //.nav__links a:first-child {
    color: black;
  }

  /*   .nav-item {
    transition: all 0.8s ease;
  } */

  .nav-enter-from {
    opacity: 0;
    transform: translateY(100px);
  }
  .nav-enter-to {
    opacity: 1;
  }
  .nav-enter-active {
    transition: all 0.5s ease;
    //position: absolute;
  }
  .nav-leave-to {
    opacity: 0;
    transform: translateY(-100px);
  }
  .nav-leave-active {
    transition: all 0.5s ease;
    //position: absolute;
  }
}
</style>