<template>
  <div v-if="content !== null" class="project">
    <div class="project__header">
      <h1 class="project__heading" v-html="content.title.rendered"></h1>
      <router-link to="/projects"> × </router-link>
    </div>
    <div
      class="page__content"
      ref="pageContent"
      v-html="content.content.rendered"
    />

    <div class="slider" v-show="showSlider == true">
      <swiper
        :slides-per-view="1"
        :centeredSlides="true"
        :loop="true"
        :navigation="{
          nextEl: '.swiper-btn-next',
          prevEl: '.swiper-btn-prev',
        }"
      >
        <swiper-slide
          v-for="slide in sliderContent"
          :key="slide"
          v-html="slide"
        >
        </swiper-slide>
      </swiper>
      <div class="swiper-navigation">
        <div class="swiper-btn-prev">&#60;</div>
        <div class="swiper-btn-next">&#62;</div>
      </div>
    </div>
  </div>

  <div v-else>Es lädt…</div>
</template>



<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Navigation]);
import "swiper/swiper.scss";

export default {
  data() {
    return {
      content: null,
      gallery: null,
      sliderContent: [],
      showSlider: false,
    };
  },

  async mounted() {
    this.getData();
  },
  updated() {
    /* grab gallery from wp-content and emit it to parent app component */
    let gallery = document.querySelector(".wp-block-gallery");
    //console.log(gallery);
    if (gallery) {
      this.showSlider = true;
      let emitedGallery = {
        gallery: gallery.firstChild.innerHTML,
        comingFrom: this.$route.path,
      };
      this.$emit("updateGallery", emitedGallery);

      // only for tablets
      this.turnGalleryIntoSlider();
    }
  },

  methods: {
    async getData() {
      let API_ROUTE = `${process.env.VUE_APP_API_ROUTE}/wp-json/wp/v2/projects?slug=${this.$route.params.project}`;
      //let API_ROUTE = `${process.env.VUE_APP_API_ROUTE}/wp-json/wp_query/args/?post_type=projects&lang=en&page_id=${this.$route.params.id}`;
      const response = await fetch(API_ROUTE);
      const data = await response.json();
      this.content = data[0];
    },
    turnGalleryIntoSlider() {
      /* check, when $refs are ready */

      const interval = setInterval(() => {
        if (this.$refs.pageContent) {
          let pageContent = Array.from(this.$refs.pageContent.children); // wp-content // all HTML elements within page-Content
          //console.log(pageContent);

          /* check wp-content and stop, when wp-gallery is found */
          /* gallery: figure wrapper of gallery ul */
          if (pageContent.length > 0) {
            let gallery = pageContent.filter((element) =>
              element.classList.contains("wp-block-gallery")
            );

            if (gallery.length > 0) {
              /* extract all images from wp-gallery and store them into sliderContent for the Swiper Slider */
              let galleryUL = gallery[0].children[0]; // -> ul
              if (galleryUL) {
                galleryUL.children.forEach((li) => {
                  this.sliderContent.push(li.innerHTML);
                });

                // grab slider and insert into wp_content tree
                // slider is hidden on desktop and mobile

                let slider = document.querySelector(".slider");
                let galleryDOM = document.querySelector(".wp-block-gallery");
                galleryDOM.appendChild(slider);
              }
            }
          }
          clearInterval(interval);
        }
      }, 50);
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>

<style lang="scss">
.project {
  position: relative;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    a,
    h1 {
      font-size: 5vh;
      line-height: 1;

      /* super special media query for square aspect ratios */
      @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
        font-size: 3.3vh;
      }
      /* super special media query for square aspect ratios */
      @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
        font-size: 4vh;
      }

      @include breakExact(700px) {
        font-size: 24px;
      }
    }

    &--concert {
      margin-bottom: 60px;
    }
  }

  h2 {
    margin-top: 60px;
  }

  &__gallery {
    ul {
      list-style: none;

      li {
        figure {
          margin-bottom: 20px;
          position: relative;
        }
      }
    }
  }
}

/* exclude wp-gallery on desktop and tablet */
.blocks-gallery-grid {
  display: none;
  @include breakExact(700px) {
    display: block;
  }
}

/* slider: only visable on tablet */
.slider {
  display: none;

  @include breakExact(1024px) {
    display: block;
  }

  @include breakExact(700px) {
    display: none;
  }
}

.swiper-container {
  overflow: hidden;
  margin: 120px auto 0 auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;

  div {
    cursor: pointer;
    font-size: 6vh;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $gray-500;
    }
  }
}

.swiper-slide {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gray-500;

  /* images inside the slider */
  figure {
    position: relative;
    height: 100%;
    width: 100%;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: $color2;
      mix-blend-mode: multiply;

      @include breakExact(1024px) {
        background: $color1;
      }

      @include breakExact(700px) {
        background: $color3;
      }
    }
  }
}
</style>