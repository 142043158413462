<template>
  <section class="frontpage" ref="app">
    <div class="nav__Container">
      <Nav />
      <Player :stopAudio="openModalMuteAudio" />

      <ul
        class="project__gallery"
        v-html="gallery"
        v-if="gallery !== null"
        @click="enlargeDesktopGallery($event)"
      ></ul>
    </div>

    <router-view class="content" v-slot="{ Component, route }">
      <transition :name="route.meta.transition || 'route'">
        <Suspense>
          <keep-alive>
            <component
              :is="Component"
              :key="route.path"
              @updateGallery="receiveGallery($event)"
              @stopAudio="stopAudio"
            />
          </keep-alive>
        </Suspense>
      </transition>
    </router-view>
  </section>

  <footer class="footer">
    <div class="footer__links">
      <router-link class="footer__link" to="/continuum-kontakt"
        >Kontakt</router-link
      >
      <router-link class="footer__link" to="/continuum-kontakt"
        >Impressum</router-link
      >
      <router-link class="footer__link" to="/privacy">Datenschutz</router-link>
    </div>
    <div class="footer__raster"></div>
  </footer>
</template>

<script>
import Intro from "@/components/Intro.vue";
import Player from "@/components/Player.vue";
import Nav from "@/components/Nav.vue";

export default {
  data() {
    return {
      gallery: null, // emited gallery HTML
      emitedFrom: null, //emited route path to check if we are on the project route
      openModalMuteAudio: false,
    };
  },
  watch: {
    /* watch, if we are on the Project-Route (where the gallery should be displayed only) */
    $route(to) {
      /* if we are on the project route: Check, if the emited gallery route (comingFrom) is the same */
      if (to.params !== this.emitedFrom) {
        this.gallery = "";
      }
    },
  },
  methods: {
    /* receive wp-gallery from Project.vue and display it on the left */
    /* Why? Gallery data is coming from API inside Project.vue but needs to be displayed on main-component */
    /* Can't be positioned absolute; Needs it's real height in the DOM */
    receiveGallery(emitedGallery) {
      if (this.$route.path == emitedGallery.comingFrom) {
        this.gallery = emitedGallery.gallery;
        this.emitedFrom = emitedGallery.comingFrom;
      } else {
        this.gallery = "";
      }
    },
    enlargeDesktopGallery(e) {
      if (window.innerWidth > 1024) {
        let image = e.target;
        image.classList.toggle("desktopGalleryImage--bigger");
      }
    },
    stopAudio() {
      // emit to Audio player, if video modal gets openend to stop audio from playing
      this.openModalMuteAudio = !this.openModalMuteAudio;
    },
  },
  components: {
    Intro,
    Nav,
    Player,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Continuum";
  src: url("./assets/fonts/Continuum-Regular.woff") format("woff"),
    url("./assets/fonts/Continuum-Regular.woff") format("woff2");
}

#app {
  font-family: "Continuum", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  animation: welcome 1s forwards ease-in-out;
}

@keyframes welcome {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

::selection {
  background: $color2;
  color: $black;

  @include breakExact(1024px) {
    background: $color1;
  }
  @include breakExact(700px) {
    background: $color3;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 5vh;
  letter-spacing: -0.3vh;
  line-height: 1;
  margin-left: -0.35vh;

  @include breakExact(700px) {
    font-size: 24px;
    //letter-spacing: 0;
  }
}

p {
  font-size: 2.3vh;
  letter-spacing: -0.04vh;

  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
    font-size: 1.8vh;
  }
  /* super special media query for square aspect ratios */
  @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
    font-size: 2vh;
  }

  @include breakExact(700px) {
    letter-spacing: 0;
  }
}

ul {
  li {
    font-size: 2.3vh;
    letter-spacing: -0.04vh;
    list-style: none;

    @include breakExact(700px) {
      letter-spacing: 0;
    }

    /* super special media query for square aspect ratios */
    @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
      font-size: 1.8vh;
    }
    /* super special media query for square aspect ratios */
    @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
      font-size: 2vh;
    }
  }
}

a {
  text-decoration: none;
  color: $black;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $gray-500;
  }

  @include breakExact(1024px) {
    &:hover {
      color: $black;
    }
  }
}

figure {
  width: 100%;
  margin: 20px 0;
  position: relative;
  transition: all 0.25s ease-in-out;

  @include breakExact(700px) {
    margin: 10px 0;
  }

  &:first-of-type {
    margin-top: 0;
  }

  img {
    filter: brightness(120%) contrast(110%) grayscale(1);
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: $color2;
    mix-blend-mode: multiply;

    @include breakExact(1024px) {
      background: $color1;
    }

    @include breakExact(700px) {
      background: $color3;
    }
  }
}

/* because in gallery figures are nested in figures */
.wp-block-gallery {
  &:after {
    display: none;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
}

figcaption {
  display: none;
}

strong {
  font-weight: 400;
}

.frontpage {
  display: flex;
  padding: 60px;
  min-height: 100vh;

  @include breakExact(1024px) {
    flex-direction: column;
  }

  @include breakExact(700px) {
    padding: 14px 10px;
  }
}

.nav,
.content {
  //flex: 1;
  flex-basis: 100%;
  //width: 50%;
}

/* To display the project gallery underneath */
.nav__Container {
  width: 100%;

  .project__gallery {
    margin-top: 400px;
    width: 50%;

    @include breakExact(1024px) {
      display: none;
    }

    li figure {
      cursor: zoom-in;
    }

    .desktopGalleryImage--bigger {
      cursor: zoom-out !important;
      width: calc(200% - 60px);
    }
  }
}

.content {
  padding: 0 20px;
  position: relative;

  @include breakExact(1024px) {
    padding: 0;
    margin-top: 20px;
  }

  /* correct font-size for unformated wordpress posts */
  div {
    font-size: 2.3vh;

    /* super special media query for square aspect ratios */
    @media (min-height: 900px) and (min-width: 1024px) and (max-width: 1150px) {
      font-size: 1.8vh;
    }
    /* super special media query for square aspect ratios */
    @media (min-height: 900px) and (min-width: 1150px) and (max-width: 1400px) {
      font-size: 2vh;
    }
  }
}

.footer {
  margin-top: 80px;
  height: 150px;

  @include breakExact(700px) {
    height: 100px;
  }

  &__links {
    padding: 10px 40px;

    @include breakExact(700px) {
      padding: 10px;
    }
  }

  &__link {
    margin-right: 20px;
    text-decoration: none;
    color: black;
  }
  &__raster {
    width: 100%;
    height: 150px;
    background-image: url("./assets/raster.png");

    @include breakExact(700px) {
      height: 100px;
    }
  }
}

/* page transitions */
.route-enter-from {
  opacity: 0;
  //transform: translateX(-20px);
}
.route-enter-to {
  opacity: 1;
}
.route-enter-active {
  transition: all 1s ease-out;
}
.route-leave-to {
  opacity: 0;
  //transform: translateX(20px);
}
.route-leave-active {
  transition: all 0s;
  position: absolute;
}

/* Intro Transition */

.IntroTransition-enter-from {
  opacity: 1;
}
.IntroTransition-leave-to {
  opacity: 0;
  transform: scale(0);
}
.IntroTransition-leave-active,
.IntroTransition-enter-active {
  transition: all 0.5s;
}
</style>
