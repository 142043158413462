<template>
  <transition name="introFade">
    <section class="intro" @mousemove="moveGrid($event)">
      <div ref="moveGridMaster" class="moveGrid moveGrid--master"></div>

      <div class="intro__left">
        <h1 ref="leftHeading" @click="leftClick">Continuu||m</h1>

        <div ref="moveGridLeft" class="moveGrid moveGrid--left"></div>
      </div>

      <div class="intro__right">
        <h1 ref="rightHeading" @click="rightClick">Elina<br />Albach</h1>
        <div ref="moveGridRight" class="moveGrid moveGrid--right"></div>
      </div>
    </section>
  </transition>
</template>


<script>
export default {
  methods: {
    leftClick() {
      this.$refs.moveGridLeft.classList.add("moveGrid--left--open");
      this.$refs.moveGridRight.classList.add("moveGrid--right--open");
      this.$refs.moveGridMaster.classList.add("moveGrid--master--open");
      this.$refs.leftHeading.style.display = "none";
      this.$refs.rightHeading.style.display = "none";
      setTimeout(() => {
        this.$router.push("/continuum-about");
      }, 1000);
    },
    rightClick() {
      this.$refs.moveGridRight.classList.add("moveGrid--right--open");
      this.$refs.moveGridLeft.classList.add("moveGrid--left--open");
      this.$refs.moveGridMaster.classList.add("moveGrid--master--open");
      this.$refs.leftHeading.style.display = "none";
      this.$refs.rightHeading.style.display = "none";
      setTimeout(() => {
        window.location.href = "http://www.elinaalbach.de/biography";
      }, 1000);
    },
    moveGrid(e) {
      let x = e.clientX * 0.01;
      let y = e.clientY * 0.1;
      this.$refs.moveGridMaster.style.backgroundPosition = `${x}px ${y}px`;
    },
    random(min, max) {
      return Math.random() * (max - min) + min;
    },
    autoMoveGrid() {
      let x = Math.floor(this.random(-50, 50));
      let y = Math.floor(this.random(-50, 50));
      this.$refs.moveGridMaster.style.backgroundPosition = `${x}px ${y}px`;
    },
    shiftEvenGrid() {
      //offset right grid, if displayWidth is dividable by 10. Otherwise grid does not seperate into 2 disiguishable parts
      if (window.innerWidth % 10 == 0) {
        this.$refs.moveGridRight.style.backgroundPosition = "10px 10px";
      }
    },
  },

  mounted() {
    //shift even grid, of window width is even
    this.shiftEvenGrid();
    window.addEventListener("resize", this.shiftEvenGrid);

    /* move grid automatically on mobile */
    if (window.innerWidth <= 700) {
      setInterval(() => {
        this.autoMoveGrid();
      }, 2000);
    }
  },
  name: "Intro",
};
</script>

<style lang="scss" scoped>
.intro {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: all 1s ease-in;
  background: white;
  padding: 0;
  margin: 0;
  z-index: 1;

  @include breakExact(1024px) {
    flex-direction: column;
  }

  &__left,
  &__right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    @include breakExact(1024px) {
      width: 100%;
      height: 50%;
    }
  }

  .moveGrid {
    position: absolute;
    background-image: url("../assets/raster.png");
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /* automatic grid transition only on mobile */
    @include breakExact(700px) {
      transition: all 10s;
      background-size: 0.8%;
    }

    &--master {
      width: 100%;
      height: 100%;
      opacity: 1;

      &--open {
        transition: all 1s ease;
        opacity: 0;
      }
    }

    &--left {
      width: 100%;
      height: 100%;

      &--open {
        transition: all 1s ease;
        transform: translateX(-102%);

        @include breakExact(1024px) {
          transform: translateY(-102%);
        }
      }
    }

    &--right {
      width: 100%;
      height: 100%;

      &--open {
        transition: all 1s ease;
        transform: translateX(102%);

        @include breakExact(1024px) {
          transform: translateY(102%);
        }
      }
    }
  }
}

h1 {
  z-index: 2;
  font-size: 8.5vh;
  text-align: center;
  transition: all 0.3ss ease-in-out;
  cursor: pointer;
  color: black;
  letter-spacing: -3px;
  line-height: 1;

  @include breakExact(700px) {
    font-size: 6.4vh;
    animation: highlight 1s ease-in-out alternate infinite;

    @keyframes highlight {
      to {
        color: white;
        transform: scale(1.05);
      }
    }
  }

  &:hover {
    color: white;
    transform: scale(1.05);
  }
}
</style>
